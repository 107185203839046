@import "./variables-and-mixins.scss";

html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: PictoPartyFont;
	// background-image: url('../static/background_ground.jpg');
	// background-size: cover;
	background-position: top;
	margin: 0;
	padding: 0;
}

#landing-dictionary {
	display: flex;
	margin-top: 50px;
	flex-direction: column;

	@include media-query(mq-xl) {
		flex-direction: row;
		margin-top: 150px;
	}
}

.create-dictionary-container,
.list-dictionaries-container,
.view-dictionary-container {
	text-align: center !important;

	&:active {
		transform: translateX(-3px) translateY(3px);
	}
}
.picto-background {
	position: absolute;
	z-index: -2;
	bottom: 0;
	overflow: hidden;

	&-background {
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		min-height: 100%;
	}
	&-road {
		width: 70%;
		left: 20%;
		bottom: 0;
		height: 15%;
	}

	&-trees {
		width: 100%;
		bottom: 8%;
	}

	@keyframes side-to-side {
		from {
			transform: translateX(-20px);
		}
		to {
			transform: translateX(20px);
		}
	}

	&-cloud {
		animation: 4s ease-in-out 0s infinite alternate side-to-side;

		&-small {
			width: 20%;
			left: 10%;
			top: 30%;
		}

		&-medium {
			width: 22%;
			left: 35%;
			top: 50%;

			animation-duration: 5s;
		}

		&-big {
			width: 35%;
			right: 5%;
			top: 35%;

			animation-duration: 6s;
		}
	}

	@keyframes up-and-down {
		from {
			transform: translateY(-15px) rotateZ(-3deg);
		}
		to {
			transform: translateY(15px) rotateZ(3deg);
		}
	}

	&-balloon {
		animation: 3s ease-in-out 0s infinite alternate up-and-down;

		&-1 {
			width: 5%;
			left: 15%;
			top: 55%;
		}

		&-2 {
			width: 5%;
			right: 10%;
			top: 60%;
			animation-duration: 4s;
		}
	}
}

.picto-logo {
	background-color: white;
	border-radius: 300px;
	border-width: 0px;
	padding: 10px;
	padding-left: 140px;
	padding-right: 140px;
	padding-left: 30px;
	padding-right: 30px;
	min-height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include media-query(mq-xs) {
	}

	@include media-query(mq-s) {
		padding-left: 45px;
		padding-right: 45px;
	}

	@include media-query(mq-m) {
		padding-left: 60px;
		padding-right: 60px;
		min-height: 100px;
	}

	@include media-query(mq-l) {
		padding-left: 100px;
		padding-right: 100px;
	}

	@include media-query(mq-xl) {
		padding-left: 150px;
		padding-right: 150px;
	}

	&-container {
		position: relative;
	}

	&-img {
		width: 100%;
	}

	&-droop {
		position: absolute;
		height: 100%;
		left: 8%;
		bottom: -72%;
		z-index: -1;
		transition: bottom 2s;

		@keyframes droop-down {
			0% {
				bottom: 0%;
			}
			100% {
				bottom: -72%;
			}
		}

		animation: 1s ease-out 0s 1 droop-down;
	}

	&-dots {
		position: absolute;
		height: 50%;
		right: 8%;
		bottom: -32%;
		z-index: -1;
	}
}

.picto-main-container {
	width: 90%;
	max-width: 900px;
	margin: auto;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 40px;
}

.picto-component-title {
	text-transform: uppercase;
	font-size: $picto-default-font-size;
	font-weight: 700;
	margin: 0;
}

.picto-component-container {
	display: block;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.picto-component-inner {
	text-align: left;

	@include media-query(mq-s) {
		display: inline-block;
		margin: auto;
	}
}

.picto-button {
	background-color: $picto-green;
	color: white;
	border-radius: $picto-border-radius;
	border-width: 4px;
	border-style: solid;
	border-color: $picto-green;
	padding-left: 20px;
	padding-right: 20px;
	min-width: $picto-width;
	max-height: $picto-height;
	font-family: PictoPartyFont;
	font-size: $picto-default-font-size;
	text-transform: uppercase;
	text-align: center;
	vertical-align: middle;
	width: 100%;
	width: -moz-available;
	width: -webkit-fill-available;

	@include media-query(mq-s) {
		width: auto;
		min-width: 220px;
	}

	&--clickable {
		&:hover {
			background-color: $picto-light-blue;
			border-color: $picto-dark-blue;
			cursor: pointer;
		}

		&:focus {
			border-color: $picto-background-blue;
		}
	}

	&--word {
		text-transform: none;
	}

	&--dictionary {
		text-transform: none;
		min-width: 225px;
	}
	&--email {
		background-color: $picto-background-blue;
		border-color: $picto-background-blue;
	}

	&:disabled {
		background-color: gray;
		border-color: gray;
	}
}
.picto-button-big {
	text-decoration: none;

	p {
		position: relative;
		top: 185px;
		margin-top: -185px;
		font-size: 40px;
		font-family: PictoPartyFont;
		text-transform: uppercase;
		text-align: center;
		line-height: 50px;
		color: #ffffff;

		span {
			font-size: 20px;
		}
	}
}

.picto-input {
	min-width: $picto-width;
	height: $picto-height;
	font-family: PictoPartyFont;
	font-size: $picto-default-font-size;
	border-radius: $picto-border-radius;
	border-width: 1px;
	border-style: solid;
	border-color: $picto-green;
	background-color: white;
	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;
	min-width: 100%;
	text-align: center;

	@include media-query(mq-s) {
		width: $picto-component-width;
	}

	&:focus {
		border-color: $picto-background-blue;
		border-width: 3px;
		outline: none;
	}

	&--ok {
		border-color: $picto-light-blue;
		border-width: 3px;
	}

	&-container {
		@include media-query(mq-s) {
			max-width: $picto-component-width;
		}
	}

	&-hint {
		font-size: $picto-default-font-size;
		overflow: wrap;
		text-align: center;
	}
}

.picto-word-box {
	background-color: $picto-background-blue;
	border-color: $picto-green;
	border-width: 10px;
	border-style: solid;
	border-radius: 6px;
	padding: 12px;
	overflow: hidden;
	transition: opacity 0.25s ease-out, height 0.25s ease-out;

	&--flex {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&-hidden {
		opacity: 0;
	}
}

.picto-dictionary-box {
	background-color: $picto-background-blue;
	border-color: $picto-green;
	border-width: 10px;
	border-style: solid;
	border-radius: 6px;
	padding: 12px;
	overflow: hidden;
	transition: opacity 0.25s ease-out, height 0.25s ease-out;

	&--flex {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&-hidden {
		opacity: 0;
	}
}

.picto-word-container {
	position: relative;
	margin: 8px;
	flex: 1 1 auto;
	width: 100%;
	width: -moz-available;
	width: -webkit-fill-available;
	max-width: 100%;
	max-width: -moz-available;
	max-width: -webkit-fill-available;

	@include media-query(mq-s) {
		width: auto;
	}
}

.picto-dictionary-container {
	position: relative;
	margin: 8px;
	flex: 1 1 auto;
	width: 100%;
	width: -moz-available;
	width: -webkit-fill-available;
	max-width: 100%;
	max-width: -moz-available;
	max-width: -webkit-fill-available;

	@include media-query(mq-s) {
		width: auto;
	}
}

.picto-error {
	&-text {
		font-size: $picto-default-font-size;
		color: white;
		padding: 10px;
		text-transform: uppercase;
		text-align: center;
	}

	&-container {
		border-radius: $picto-border-radius;
		background-color: $picto-red;
		min-width: 100%;

		@include media-query(mq-s) {
			width: $picto-component-width;
		}
	}

	&-hidden {
		display: none;
	}
}

.picto-word-title {
	text-align: center;
}

.picto-button {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.picto-input-container {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.picto-delete-word {
	display: block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	border: 0px solid;
	border-radius: 50%;
	color: #f5f5f5;
	text-align: center;
	text-decoration: none;
	background-color: $picto-red;
	font-size: 12px;
	font-weight: bold;
	float: right;
	cursor: pointer;
	position: absolute;
	right: -6px;
	top: -6px;
}

.picto-add-word {
	flex-wrap: wrap;
	font-family: Arial, sans-serif;
	min-width: 60px;
	max-width: 60px;
	margin: 0 0 0 10px;
}
.picto-add-email {
	margin: 0 0 0 10px;
	min-width: 80px;
	max-width: 80px;
}

.word-enter-active {
	transition: opacity 0.5s;
}

.word-enter {
	opacity: 0;
}

.disabled {
	opacity: 0.5;
}

.options-container {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	flex-flow: column;

	@include media-query(mq-m) {
		flex-flow: row;
	}

	.tag-container {
		width: 100%;

		@include media-query(mq-m) {
			margin-left: 8px;
		}
	}
	.filter-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: left;

		@include media-query(mq-m) {
			flex-flow: row;
			min-width: 310px;
			max-width: 310px;
		}
	}
}
.sort-input {
	@extend .picto-input;
	// override
	@include media-query(mq-s) {
		width: 100%;
	}
}

.sort-container {
	max-width: 100%;
	min-width: 100%;
	@include media-query(mq-m) {
		max-width: 150px;
		min-width: 150px;
	}
}
.lang-input {
	@extend .picto-input;
	// override
	@include media-query(mq-s) {
		width: 100%;
	}
}

.lang-container {
	max-width: 100%;
	min-width: 100%;
	@include media-query(mq-m) {
		max-width: 150px;
		min-width: 150px;
	}
}
.tag-input-container {
	.tag-input {
		text-transform: uppercase;
		@extend .picto-input;
		// override
		@include media-query(mq-s) {
			width: 100%;
		}
	}
}

.picto-dictionary-container-info {
	font-size: 24px;
	color: white;
}

.arrow-button {
	display: inline-block;
	min-width: 24px !important;
	max-width: 24px !important;
	user-select: none;
	transition: opacity 0.25s;
}

.current-page {
	display: inline-block;
	user-select: none;
	min-width: 60px;
	max-width: 60px;

	@include media-query(mq-s) {
		min-width: 88px;
		max-width: 88px;
	}
}

.low-opacity {
	opacity: 0.75;
}
.picto-id {
	&-tag {
		text-transform: uppercase;
		text-align: left;
	}
}

.picto-input {
	text-align: center;
}

.picto-qr {
	&-container {
		background-color: white;
		border-radius: $picto-border-radius;
		border-color: gray;
		border-width: 1px;
		border-style: solid;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-svg-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		svg {
			width: 90%;
			height: 90%;
		}
	}
}

.picto-name {
	font-size: $picto-default-font-size * 2;
	color: $picto-green;
	font-weight: bold;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
}
.picto-creator {
	font-size: $picto-default-font-size;
	color: black;
	font-weight: bold;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
}

.picto-id-title {
	text-align: center;
}

.picto-button {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.picto-show-hide-words-button {
	margin-top: 20px;
	margin-bottom: 20px;
}
.view-dictionary-id-form {
	display: inline-block;
}
