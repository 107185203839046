//@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';

@font-face {
	font-family: PictoPartyFont;
	src: url("../assets/fonts/engine-regular-webfont.woff2") format("woff2"),
		url("../assets/fonts/engine-regular-webfont.woff") format("woff");
}

$picto-green: rgb(66, 138, 133);
$picto-red: rgb(181, 111, 112);
$picto-light-blue: rgb(75, 144, 173);
$picto-dark-blue: rgb(0, 81, 157);
$picto-background-blue: rgb(124, 130, 176);

$picto-component-width: 420px;

$picto-width: 160px;
$picto-height: 49px;
$picto-border-radius: 14px;

$picto-default-font-size: 24px;

$break-xs: 320px;
$break-s: 480px;
$break-m: 600px;
$break-l: 728px;
$break-xl: 1024px;

@mixin media-query($media) {
	@if $media == mq-xs {
		@media only screen and (min-width: $break-xs) {
			@content;
		}
	} @else if $media == mq-s {
		@media only screen and (min-width: $break-s) {
			@content;
		}
	} @else if $media == mq-m {
		@media only screen and (min-width: $break-m) {
			@content;
		}
	} @else if $media == mq-l {
		@media only screen and (min-width: $break-l) {
			@content;
		}
	} @else if $media == mq-xl {
		@media only screen and (min-width: $break-xl) {
			@content;
		}
	}
}
